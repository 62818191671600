import axios from 'axios';
import { AliasCustomFieldValueType } from 'src/common/interfaces/journey/alias.interface';
import { Nullable } from 'src/types/nullable.type';
import { UserEventInterface } from './event.interface';

import { journeyPostJson  } from '../journeyApi';

export type BatchSingleEvent = {
  event_type: string;
  event_uuid: string;
  node_uuid: string;
  block_uuid?: string;
  content_uuid?: string;
  data: { duration: number };
};

export type BatchEvent = BatchSingleEvent[];

export class BatchUserEvent {
  protected aliasUUID: string;
  protected email: Nullable<string>;
  protected trackingUUID: string;
  protected sessionUUID: string;
  protected sessionReplayUUID?: Nullable<string>;

  constructor(params: UserEventInterface) {
    this.aliasUUID = params.aliasUUID;
    this.email = params.email || null;
    this.trackingUUID = params.trackingUUID;
    this.sessionUUID = params.sessionUUID;
    this.sessionReplayUUID = params.sessionReplayUUID || null;
  }

  
  fireAll(events: BatchEvent) {
    const url = `/api/journey-aliases/${this.aliasUUID}/events/batch`;

    const params = {            
      email: this.email,
      tracking_uuid: this.trackingUUID,
      session_uuid: this.sessionUUID,
      session_replay_uuid: this.sessionReplayUUID,
      events: events,
    };

    return journeyPostJson(url, params);
  }
}